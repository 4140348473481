import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, ref, onMounted } from 'vue';
import { selectJobByParam, selectDepartmentList } from "/src/api/personnel";
import { selectCheckindayByPagination, selectCheckinmonthByPagination, selectCheckinyearByPagination, insertCheckinReason, updateHyCheckdayByState, updateState, selectClockInRecordAll, selectFirstTimeByCheckinDay, selectEmployeeByDepartmentId, selectCheckinDayDtoByParam } from "/src/api/attendance";
import useTablePagination from '@/hooks/pagination';
import moment from 'moment';
const columnsDay1 = [{
  title: '日期',
  dataIndex: 'date',
  width: 110,
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '上班打卡时间',
  dataIndex: 'workClockInFirst',
  slots: {
    customRender: 'workClockInFirst'
  },
  align: 'center'
}, {
  title: '上班打卡结果',
  dataIndex: 'workClockInFirstResult',
  align: 'center'
}, {
  title: '下班打卡时间',
  dataIndex: 'closeClockFirst',
  slots: {
    customRender: 'closeClockFirst'
  },
  align: 'center'
}, {
  title: '下班打卡结果',
  dataIndex: 'closeClockFirstResult',
  align: 'center'
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay0 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay2 = [{
  title: '日期',
  dataIndex: 'date',
  width: 110,
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay3 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay4 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay5 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult',
    align: 'center'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay6 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult',
    align: 'center'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult',
    align: 'center'
  }]
}, {
  title: '第六次上班',
  dataIndex: 'payNode11',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSixth',
    slots: {
      customRender: 'workClockInSixth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSixthResult',
    align: 'center'
  }]
}, {
  title: '第六次下班',
  dataIndex: 'payNode12',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSixth',
    slots: {
      customRender: 'closeClockSixth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSixthResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay01 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '上班打卡时间',
  dataIndex: 'workClockInFirst',
  slots: {
    customRender: 'workClockInFirst'
  },
  align: 'center'
}, {
  title: '上班打卡结果',
  dataIndex: 'workClockInFirstResult',
  align: 'center'
}, {
  title: '下班打卡时间',
  dataIndex: 'closeClockFirst',
  slots: {
    customRender: 'closeClockFirst'
  },
  align: 'center'
}, {
  title: '下班打卡结果',
  dataIndex: 'closeClockFirstResult',
  align: 'center'
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay02 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    align: 'center',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay03 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay04 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay05 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult',
    align: 'center'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay06 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult',
    align: 'center'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult',
    align: 'center'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult',
    align: 'center'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult',
    align: 'center'
  }]
}, {
  title: '第六次上班',
  dataIndex: 'payNode11',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSixth',
    slots: {
      customRender: 'workClockInSixth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSixthResult',
    align: 'center'
  }]
}, {
  title: '第六次下班',
  dataIndex: 'payNode12',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSixth',
    slots: {
      customRender: 'closeClockSixth'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSixthResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsMonth = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  width: 120,
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  width: 120,
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  width: 120,
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  width: 120,
  align: 'center'
}, {
  title: '应出勤天数',
  dataIndex: 'attendanceDays',
  width: 120,
  align: 'center'
}, {
  title: '实际出勤天数',
  dataIndex: 'workDays',
  width: 120,
  align: 'center'
}, {
  title: '迟到次数',
  dataIndex: 'lateDays',
  width: 100,
  align: 'center'
}, {
  title: '早退次数',
  dataIndex: 'earlyDays',
  width: 100,
  align: 'center'
}, {
  title: '缺卡时段个数',
  dataIndex: 'halfDayCount',
  width: 130,
  align: 'center'
}, {
  title: '缺卡次数总和(次)',
  dataIndex: 'missTimesCount',
  width: 160,
  align: 'center'
}, {
  title: '请假总时长(天)',
  dataIndex: 'totalTimeDay',
  width: 130,
  align: 'center'
}, {
  title: '加班总时长(小时)',
  dataIndex: 'overTotalHours',
  width: 160,
  align: 'center'
}];
const columnsDay = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left',
  align: 'center'
}, {
  title: '姓名',
  dataIndex: 'name',
  fixed: 'left',
  align: 'center'
}, {
  title: '工号',
  dataIndex: 'workNo',
  align: 'center'
}, {
  title: '所属部门',
  dataIndex: 'departmentName',
  align: 'center'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  },
  align: 'center'
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult',
    align: 'center'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult',
    align: 'center'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult',
    align: 'center'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult',
    align: 'center'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult',
    align: 'center'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult',
    align: 'center'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult',
    align: 'center'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    },
    align: 'center'
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult',
    align: 'center'
  }]
}, {
  title: '缺卡次数总和(次)',
  dataIndex: 'missTimesCount',
  align: 'center'
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours',
  align: 'center'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
export default defineComponent({
  setup() {
    onMounted(() => {
      for (let i = 2021; i <= 2030; i++) {
        yearsOptions.value.push({
          value: i.toString(),
          label: i.toString()
        });
      }

      dateDay.value = defaultSelectDate.dayDate;
      dateMonth.value = defaultSelectDate.monthDate;
      selectCheckindayByPagination1();
      getDepartmentList();
    });
    let yearsOptions = ref([]);
    let loading = ref(false);
    let btnLoading = ref(false);
    let tableDataDay = ref([]);
    let tableDataMonth = ref([]);
    let downloadData = ref([]);
    let activeKey = ref('1');
    const addVisible = ref(false);
    const changeVisible = ref(false);
    const formStateAdd = reactive({
      id: null,
      reason: null
    });
    const formStateChange = reactive({
      id: null,
      date: null,
      name: null,
      firstAmTime: null,
      lastAmTime: null,
      firstPmTime: null,
      lastPmTime: null,
      overStartTime: null,
      overEndTime: null
    });

    const openAddReason = record => {
      addVisible.value = true;
      Object.assign(formStateAdd, {
        id: record.id,
        reason: null
      });
    };

    const handleCancelAdd = () => {
      addVisible.value = false;
      btnLoading.value = false;
    };

    const handleCancelChange = () => {
      changeVisible.value = false;
      btnLoading.value = false;
    };

    const onSubmitAdd = () => {
      if (formStateAdd.reason == null || formStateAdd.reason == '') {
        _message.error('请输入原因');

        return;
      }

      insertCheckinReason1();
    }; ///////////////


    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (activeKey.value == '1') {
        selectCheckindayByPagination1();
      } else if (activeKey.value == '2') {
        selectCheckinmonthByPagination1();
      } else if (activeKey.value == '3') {
        selectCheckinyearByPagination1();
      } else if (activeKey.value == '4') {
        selectCheckindayByPagination1();
      }
    };

    const dateDay = ref();
    const dateMonth = ref();
    const dateYears = ref('2021');

    const onSubmit = () => {
      if ((formState.workNo == '' || formState.workNo == null) && (formState.name == '' || formState.name == null)) {
        _message.error('请输入姓名或工号');

        return;
      }

      selectCheckindayByPagination1();
    };

    const formState = reactive({
      name: '',
      workNo: '',
      data: []
    }); //设置默认日期

    const defaultSelectDate = {
      dayDate: moment().startOf('day').subtract(1, 'days'),
      monthDate: moment().startOf('month').subtract(0, 'days')
    }; //时间戳转化日期

    const format = shijianchuo => {
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    };

    const add0 = m => {
      return m < 10 ? '0' + m : m;
    }; //选择日期


    const changeDay = () => {
      current.value = 1;
      pageSize.value = 10;
      selectCheckindayByPagination1();
    };

    const changeMonth = () => {
      current.value = 1;
      pageSize.value = 10;
      selectCheckinmonthByPagination1();
    };

    const changeYear = () => {
      current.value = 1;
      pageSize.value = 10;
      selectCheckinyearByPagination1();
    }; //切换标签页


    const changeTabs = () => {
      current.value = 1;
      pageSize.value = 10;
      total.value = 0;
      dateDay.value = defaultSelectDate.dayDate;
      dateMonth.value = defaultSelectDate.monthDate;

      if (activeKey.value == '1') {
        selectCheckindayByPagination1();
      } else if (activeKey.value == '2') {
        selectCheckinmonthByPagination1();
      } else if (activeKey.value == '3') {
        selectCheckinyearByPagination1();
      } else if (activeKey.value == '4') {
        tableDataDay.value = [];
        Object.assign(formState, {
          name: '',
          workNo: '',
          month: ''
        });
      }
    }; //获取考勤日列表


    const selectCheckindayByPagination1 = async () => {
      let param = {};

      if (activeKey.value == '4') {
        let [startDate, endDate] = formState.data;
        param = {
          userName: formState.name,
          workNo: formState.workNo,
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10,
          startDate,
          endDate
        };
      } else {
        param = {
          date: formStateForSearch.dateDay,
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10,
          departmentId: formStateForSearch.departmentId
        };
      }

      loading.value = true;
      let res = await selectCheckindayByPagination(param);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableDataDay.value = [];
        let timesNum = [];

        if (list && list.length) {
          list.filter((item, index) => {
            tableDataDay.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              workNo: item.workNo,
              departmentName: item.departmentName,
              amTime: (item.firstAmTime == null ? '未打卡' : item.firstAmTime) + '~' + (item.lastAmTime == null ? '未打卡' : item.lastAmTime),
              pmTime: (item.firstPmTime == null ? '未打卡' : item.firstPmTime) + '~' + (item.lastPmTime == null ? '未打卡' : item.lastPmTime),
              overStartEndTime: item.overStartTime == null && item.overEndTime == null ? '未加班' : (item.overStartTime == null ? '未打卡' : item.overStartTime) + '~' + (item.overEndTime == null ? '未打卡' : item.overEndTime),
              isLate: item.isLate,
              isEarly: item.isEarly,
              reason: item.reason,
              // overtime: item.overHours,
              state: item.state,
              isUpdated: item.isUpdated,
              firstAmTime: item.firstAmTime,
              lastAmTime: item.lastAmTime,
              firstPmTime: item.firstPmTime,
              lastPmTime: item.lastPmTime,
              overStartTime: item.overStartTime,
              overEndTime: item.overEndTime,
              userNo: item.userNo,
              id: item.id,
              workClockInFirst: item.workClockInFirstResult === '缺卡' || item.workClockInFirstResult === '缺勤' ? '补卡' : item.workClockInFirst,
              workClockInFirstResult: item.workClockInFirstResult,
              closeClockFirst: item.closeClockFirstResult === '缺卡' || item.closeClockFirstResult === '缺勤' ? '补卡' : item.closeClockFirst,
              closeClockFirstResult: item.closeClockFirstResult,
              workClockInSecond: item.workClockInSecondResult === '缺卡' || item.workClockInSecondResult === '缺勤' ? '补卡' : item.workClockInSecond,
              workClockInSecondResult: item.workClockInSecondResult,
              closeClockSecond: item.closeClockSecondResult === '缺卡' || item.closeClockSecondResult === '缺勤' ? '补卡' : item.closeClockSecond,
              closeClockSecondResult: item.closeClockSecondResult,
              workClockInThird: item.workClockInThirdResult === '缺卡' || item.workClockInThirdResult === '缺勤' ? '补卡' : item.workClockInThird,
              workClockInThirdResult: item.workClockInThirdResult,
              closeClockThird: item.closeClockThirdResult === '缺卡' || item.closeClockThirdResult === '缺勤' ? '补卡' : item.closeClockThird,
              closeClockThirdResult: item.closeClockThirdResult,
              restClockStartTime: item.restClockStartTimeResult === '缺卡' || item.restClockStartTimeResult === '缺勤' ? '补卡' : item.restClockStartTime,
              restClockStartTimeResult: item.restClockStartTimeResult,
              restClockEndTime: item.restClockEndTimeResult === '缺卡' || item.restClockEndTimeResult === '缺勤' ? '补卡' : item.restClockEndTime,
              restClockEndTimeResult: item.restClockEndTimeResult,
              workClockInFourth: item.workClockInFourthResult === '缺卡' || item.workClockInFourthResult === '缺勤' ? '补卡' : item.workClockInFourth,
              workClockInFourthResult: item.workClockInFourthResult,
              closeClockFourth: item.closeClockFourthResult === '缺卡' || item.closeClockFourthResult === '缺勤' ? '补卡' : item.closeClockFourth,
              closeClockFourthResult: item.closeClockFourthResult,
              workClockInFifth: item.workClockInFifthResult === '缺卡' || item.workClockInFifthResult === '缺勤' ? '补卡' : item.workClockInFifth,
              workClockInFifthResult: item.workClockInFifthResult,
              closeClockFifth: item.closeClockFifthResult === '缺卡' || item.closeClockFifthResult === '缺勤' ? '补卡' : item.closeClockFifth,
              closeClockFifthResult: item.closeClockFifthResult,
              workClockInSixth: item.workClockInSixthResult === '缺卡' || item.workClockInSixthResult === '缺勤' ? '补卡' : item.workClockInSixth,
              workClockInSixthResult: item.workClockInSixthResult,
              overTotalHours: item.overTotalHours,
              workTimeTrue: {
                workingTimeFirst: item.workingTimeFirst,
                closingTimeFirst: item.closingTimeFirst,
                restStartTime: item.restStartTime,
                restEndTime: item.restEndTime,
                workingTimeSecond: item.workingTimeSecond,
                closingTimeSecond: item.closingTimeSecond,
                workingTimeThird: item.workingTimeThird,
                closingTimeThird: item.closingTimeThird,
                restClockStartTime: item.restClockStartTime,
                restClockEndTime: item.restClockEndTime,
                workClockInFourth: item.workClockInFourth,
                closeClockFourth: item.closeClockFourth,
                workClockInFifth: item.workClockInFifth,
                closeClockFifth: item.closeClockFifth,
                workClockInSixth: item.workClockInSixth,
                closeClockSixth: item.closeClockSixth,
                workingTimeFourth: item.workingTimeFourth,
                closingTimeFourth: item.closingTimeFourth,
                workingTimeFifth: item.workingTimeFifth,
                closingTimeFifth: item.closingTimeFifth,
                workingTimeSixth: item.workingTimeSixth,
                closingTimeSixth: item.closingTimeSixth,
                state: item.state,
                timesNum: item.timesNum
              },
              timesNum: item.timesNum,
              state: item.state
            });
            daySate.state = item.state; // daySate.timesNum = item.timesNum

            isShowRestDay.value = item.isShowRestDay;
            timesNum.push(item.timesNum);
          });
          daySate.timesNum = Math.max(...timesNum);
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //获取考勤月列表


    const selectCheckinmonthByPagination1 = async () => {
      loading.value = true;
      let res = await selectCheckinmonthByPagination({
        // date: format(dateMonth.value).slice(0,7),
        pageNumber: current.value ? current.value : 1,
        limit: pageSize.value ? pageSize.value : 10,
        departmentId: formStateForSearch.departmentId,
        date: formStateForSearch.dateMonth
      });

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableDataMonth.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableDataMonth.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              workNo: item.workNo,
              departmentName: item.departmentName,
              attendanceDays: item.attendanceDays,
              lateDays: item.lateDays,
              earlyDays: item.earlyDays,
              overTotalHours: item.overTotalHours,
              missTimesCount: item.missTimesCount,
              totalTimeDay: item.totalTimeDay,
              userNo: item.userNo,
              workDays: item.workDays,
              halfDayCount: item.halfDayCount
            });
            console.log(Math.floor(item.overTotalHours / 60).toString().length);
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //获取考勤年列表


    const selectCheckinyearByPagination1 = async () => {
      loading.value = true;
      let res = await selectCheckinyearByPagination({
        date: formStateForSearch.dateYears,
        pageNumber: current.value ? current.value : 1,
        limit: pageSize.value ? pageSize.value : 10,
        departmentId: formStateForSearch.departmentId
      });

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableDataMonth.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableDataMonth.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              workNo: item.workNo,
              departmentName: item.departmentName,
              attendanceDays: item.attendanceDays,
              lateDays: item.lateDays,
              earlyDays: item.earlyDays,
              overTotalHours: item.overTotalHours,
              missTimesCount: item.missTimesCount,
              totalTimeDay: item.totalTimeDay,
              userNo: item.userNo,
              workDays: item.workDays,
              halfDayCount: item.halfDayCount
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //添加迟到早退原因


    const insertCheckinReason1 = async () => {
      let param = {
        id: formStateAdd.id,
        reason: formStateAdd.reason
      };
      btnLoading.value = true;
      let res = await insertCheckinReason(param);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message);

        handleCancelAdd();
        selectCheckindayByPagination1();
        Object.assign(formStateAdd, {
          id: null,
          reason: null
        });
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //打开修正数据弹窗


    const changeData = record => {
      changeVisible.value = true;
      Object.assign(formStateChange, {
        id: record.userNo,
        date: record.date,
        name: record.name,
        firstAmTime: record.firstAmTime == null ? null : moment(record.firstAmTime, 'HH:mm'),
        lastAmTime: record.lastAmTime == null ? null : moment(record.lastAmTime, 'HH:mm'),
        firstPmTime: record.firstPmTime == null ? null : moment(record.firstPmTime, 'HH:mm'),
        lastPmTime: record.lastPmTime == null ? null : moment(record.lastPmTime, 'HH:mm'),
        overStartTime: record.overStartTime == null ? null : moment(record.overStartTime, 'HH:mm'),
        overEndTime: record.overEndTime == null ? null : moment(record.overEndTime, 'HH:mm')
      });
    }; //提交修正数据


    const onSubmitChange = async () => {
      let param = {
        userNo: formStateChange.id,
        createTime: formStateChange.date,
        firstAmTime: formStateChange.firstAmTime == null ? null : formStateChange.date + ' ' + formStateChange.firstAmTime.format('HH:mm'),
        lastAmTime: formStateChange.lastAmTime == null ? null : formStateChange.date + ' ' + formStateChange.lastAmTime.format('HH:mm'),
        firstPmTime: formStateChange.firstPmTime == null ? null : formStateChange.date + ' ' + formStateChange.firstPmTime.format('HH:mm'),
        lastPmTime: formStateChange.lastPmTime == null ? null : formStateChange.date + ' ' + formStateChange.lastPmTime.format('HH:mm'),
        overStartTime: formStateChange.overStartTime == null ? null : formStateChange.date + ' ' + formStateChange.overStartTime.format('HH:mm'),
        overEndTime: formStateChange.overEndTime == null ? null : formStateChange.date + ' ' + formStateChange.overEndTime.format('HH:mm')
      };
      btnLoading.value = true;
      let res = await updateHyCheckdayByState(param);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message);

        handleCancelChange();
        selectCheckindayByPagination1();
        Object.assign(formStateChange, {
          id: null,
          date: null,
          name: null,
          firstAmTime: null,
          lastAmTime: null,
          firstPmTime: null,
          lastPmTime: null,
          overStartTime: null,
          overEndTime: null
        });
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //数据确认正常


    const checkin = async record => {
      let param = {
        id: record.id
      };
      let res = await updateState(param);

      if (res.code === 200) {
        _message.success(res.message);

        selectCheckindayByPagination1();
      } else {
        _message.error(res.message);
      }
    }; // //获取下载数据
    // const selectClockInRecordAll1 = async() => {
    //   let res = await selectClockInRecordAll({
    //     recordTime: format(dateMonth.value).slice(0,7),
    //   });
    //   if(res.code === 200){
    //     let data = res.data;
    //     downloadData.value = [];
    //     if(data){
    //       Object.keys(data).filter((key,index)=>{
    //         let arr = {
    //           date: format(dateMonth.value).slice(0,7),
    //           name: key,
    //         };
    //         Object.keys(data[key]).filter((item,index1)=>{
    //           data[key][item].filter((itemTime,indexTime)=>{
    //             data[key][item][indexTime] = itemTime.slice(11,16)
    //           });
    //           arr['date'+(index1+1)] = data[key][item].join('\n');
    //         })
    //         downloadData.value.push(arr);
    //       });
    //       handleDownExcel();
    //     }
    //   }else {
    //     loading.value = false;
    //     message.error(res.message);
    //   }
    // }
    // //下载  下载后需手动点击“自动换行”
    // const handleDownExcel = () => {
    //   require.ensure([], () => {
    //     const {
    //       export_json_to_excel
    //     } = require("@/assets/excel/Export2Excel");  
    //     const tHeader = ["日期", "姓名"]; // 导出的excel表头名信息
    //     let year = format(dateMonth.value).slice(0,4);
    //     let mon = format(dateMonth.value).slice(5,7);
    //     if( year%4==0 && mon=='02'){
    //       for(let i=1;i<=29;i++){
    //         tHeader.push(i)
    //       }
    //     }else if(year%4!=0 && mon=='02'){
    //       for(let i=1;i<=28;i++){
    //         tHeader.push(i)
    //       }
    //     }else if(mon=='01'||mon=='03'||mon=='05'||mon=='07'||mon=='08'||mon=='10'||mon=='12'){
    //       for(let i=1;i<=31;i++){
    //         tHeader.push(i)
    //       }
    //     }else{
    //       for(let i=1;i<=30;i++){
    //         tHeader.push(i)
    //       }
    //     }
    //     const filterVal = [
    //       "date",
    //       "name",
    //     ]; // 导出的excel表头字段名，需要导出表格字段名
    //     for(let i=1;i<=tHeader.length-2;i++){
    //       filterVal.push('date'+i);
    //     }
    //     console.log(downloadData.value);
    //     const list = downloadData.value;
    //     const data = formatJson(filterVal, list);
    //     export_json_to_excel(tHeader, data, format(dateMonth.value).slice(0,7)+"考勤详细报表"); // 导出的表格名称，根据需要自己命名
    //   });
    // }
    // //格式转换
    // const formatJson = (filterVal, jsonData)=>{
    //   return jsonData.map(v => filterVal.map(j => v[j]));
    // }


    const daySate = reactive({
      timesNum: 1,
      state: 0
    });
    const tableColumnsDay = computed(() => {
      if (daySate.state === 1) {
        return columnsDay0;
      } else {
        if (daySate.timesNum === 1) {
          return columnsDay1;
        }

        if (daySate.timesNum === 2) {
          return columnsDay2;
        }

        if (daySate.timesNum === 3) {
          return columnsDay3;
        }

        if (daySate.timesNum === 4) {
          return columnsDay4;
        }

        if (daySate.timesNum === 5) {
          return columnsDay5;
        }

        if (daySate.timesNum === 6) {
          return columnsDay6;
        }
      }
    }); //补卡相关

    const changeVisibleDay = ref(false);
    const formStateChangeDay = reactive({
      userNo: null,
      createTime: null,
      timesNum: null,
      state: null,
      type: null,
      value: null,
      name: null,
      restClockStartTime: null,
      restClockEndTime: null,
      title: null
    });
    const formRef = ref();

    const handleCancelChangeDay = () => {
      // console.log(formStateChangeDay.restClockStartTime,formStateChangeDay.restClockEndTime)
      formRef.value.validate().then(async () => {
        btnLoading.value = true;
        let obj = {};
        Object.assign(obj, formStateChangeDay);
        obj.value = `${obj.createTime} ${obj.value}`;

        if (obj.state === 1) {
          obj.value = `${obj.createTime} ${obj.restClockStartTime},${obj.createTime} ${obj.restClockEndTime}`;
        } // console.log(obj)


        let res = await updateHyCheckdayByState(obj);

        if (res.code === 200) {
          btnLoading.value = false;

          _message.success(res.message);

          changeVisibleDay.value = false;
          selectCheckindayByPagination1();
        } else {
          btnLoading.value = false;

          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleClickMakeUpCard = (num, val, title) => {
      // console.log(val)
      changeVisibleDay.value = true;
      let obj = {
        userNo: val.userNo,
        createTime: val.date,
        timesNum: val.timesNum,
        state: val.state,
        type: num,
        value: null,
        name: val.name,
        restClockStartTime: val.restClockStartTime == '补卡' ? null : val.restClockStartTime,
        restClockEndTime: val.restClockEndTime == '补卡' ? null : val.restClockEndTime,
        title
      };
      Object.assign(formStateChangeDay, obj);
    }; //个人列表


    const isShowRestDay = ref(false);
    const tableColumnsPeople = computed(() => {
      if (isShowRestDay.value) {
        if (daySate.timesNum === 1) {
          return columnsDay01;
        }

        if (daySate.timesNum === 2) {
          return columnsDay02;
        }

        if (daySate.timesNum === 3) {
          return columnsDay03;
        }

        if (daySate.timesNum === 4) {
          return columnsDay04;
        }

        if (daySate.timesNum === 5) {
          return columnsDay05;
        }

        if (daySate.timesNum === 6) {
          return columnsDay06;
        }
      } else {
        if (daySate.timesNum === 1) {
          return columnsDay1;
        }

        if (daySate.timesNum === 2) {
          return columnsDay2;
        }

        if (daySate.timesNum === 3) {
          return columnsDay3;
        }

        if (daySate.timesNum === 4) {
          return columnsDay4;
        }

        if (daySate.timesNum === 5) {
          return columnsDay5;
        }

        if (daySate.timesNum === 6) {
          return columnsDay6;
        }
      }
    }); //部门信息

    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //搜索条件


    const formStateForSearch = reactive({
      dateDay: moment().startOf('day').format('YYYY-MM-DD'),
      departmentId: null,
      dateMonth: moment().startOf('day').format('YYYY-MM'),
      dateYears: moment().startOf('day').format('YYYY')
    });
    const formRefForSearch = ref();

    const onSubmitForSearch = val => {
      current.value = 1;
      pageSize.value = 10;

      switch (val) {
        case 1:
          selectCheckindayByPagination1();
          break;

        case 2:
          selectCheckinmonthByPagination1();
          break;

        case 3:
          selectCheckinyearByPagination1();
          break;
      }
    };

    const resetForSearch = val => {
      current.value = 1;
      pageSize.value = 10;
      formRefForSearch.value.resetFields();

      switch (val) {
        case 1:
          selectCheckindayByPagination1();
          break;

        case 2:
          selectCheckinmonthByPagination1();
          break;

        case 3:
          selectCheckinyearByPagination1();
          break;
      }
    }; //下载


    const downloadVisible = ref(false);
    const firstTimeByCheckinDay = ref('');

    const handleDownload = async () => {
      let res = await selectFirstTimeByCheckinDay();

      if (res.code === 200) {
        if (!res.data) {
          return _message.error('暂无考勤可下载');
        }

        downloadVisible.value = true;
        formStateForDownload.departmentId = null;
        formStateForDownload.userName = null;
        formStateForDownload.data = [];
        firstTimeByCheckinDay.value = res.data;
      } else {
        _message.error(res.message);
      }
    };

    const formStateForDownload = reactive({
      departmentId: null,
      userName: null,
      data: []
    });
    const downloadLoading = ref(false);

    const handleOkDownload = async () => {
      if (!formStateForDownload.data.length) {
        return _message.error('请选择日期范围');
      }

      downloadLoading.value = true;
      let res = await selectEmployeeByDepartmentId({
        departmentId: formStateForDownload.departmentId,
        userName: formStateForDownload.userName
      });

      if (res.code === 200) {
        const param = {
          departmentId: formStateForDownload.departmentId,
          userName: formStateForDownload.userName,
          startDate: formStateForDownload.data[0],
          endDate: formStateForDownload.data[1]
        };
        handleDownExcel(param);
      } else {
        downloadLoading.value = false;

        _message.error(res.message);
      }

      downloadLoading.value = false;
    };

    const disabledDate = current => {
      return current && current < moment(firstTimeByCheckinDay.value).startOf('day') || current && moment().endOf('day') < current;
    };

    const handleDownExcel = async param => {
      let res = await selectCheckinDayDtoByParam(param);

      if (res.code === 200) {
        // console.log(res)
        const downData = [];
        let downSate = {
          state: null,
          timesNum: null
        };
        let downIsShowRestDay = true;

        if (res.data && res.data.length) {
          let timesNum = [];
          res.data.forEach(item => {
            downData.push({ ...item,
              date: item.createTime,
              name: item.userName,
              departmentName: item.departmentName
            });
            downSate.state = item.state;
            timesNum.push(item.timesNum);
            downIsShowRestDay = item.isShowRestDay;
          });
          downSate.timesNum = Math.max(...timesNum);
        }

        const tableColumns = () => {
          if (downIsShowRestDay) {
            if (downSate.timesNum === 1) {
              return columnsDay01;
            }

            if (downSate.timesNum === 2) {
              return columnsDay02;
            }

            if (downSate.timesNum === 3) {
              return columnsDay03;
            }

            if (downSate.timesNum === 4) {
              return columnsDay04;
            }

            if (downSate.timesNum === 5) {
              return columnsDay05;
            }

            if (downSate.timesNum === 6) {
              return columnsDay06;
            }
          } else {
            if (downSate.timesNum === 1) {
              return columnsDay1;
            }

            if (downSate.timesNum === 2) {
              return columnsDay2;
            }

            if (downSate.timesNum === 3) {
              return columnsDay3;
            }

            if (downSate.timesNum === 4) {
              return columnsDay4;
            }

            if (downSate.timesNum === 5) {
              return columnsDay5;
            }

            if (downSate.timesNum === 6) {
              return columnsDay6;
            }
          }
        };

        require.ensure([], () => {
          const {
            export_json_to_excel3
          } = require("@/assets/excel/Export2Excel");

          const tHeader = []; // 导出的excel表头名信息

          const tHeader1 = []; // 导出的excel表头名信息

          const filterVal = [];
          let tableColumnsData = tableColumns();
          tableColumnsData.forEach(item => {
            if (item.dataIndex !== 'workTimeTrue') {
              tHeader.push(item.title); // console.log(item.dataIndex)

              if (item.dataIndex.indexOf('payNode') === -1) {
                filterVal.push(item.dataIndex);
              }

              if (item.children && item.children.length) {
                item.children.forEach((ixt, inx) => {
                  if (inx == 0) {
                    tHeader.push("");
                  }

                  tHeader1.push(ixt.title);
                  filterVal.push(ixt.dataIndex);
                });
              } else {
                tHeader1.push("");
              }
            }
          });
          const data = formatJsonDown(filterVal, downData);
          const merges = [];

          let alphabet = num => String.fromCharCode(64 + parseInt(num));

          tHeader.forEach((item, index) => {
            if (item === '') {
              merges.push(`${alphabet(index)}1:${alphabet(index + 1)}1`);
            }
          });
          tHeader1.forEach((item, index) => {
            if (item === '') {
              merges.push(`${alphabet(index + 1)}1:${alphabet(index + 1)}2`);
            }
          }); // console.log('merges',merges,tHeader,tHeader1,filterVal)

          export_json_to_excel3(tHeader, tHeader1, data, `考勤绩效`, merges); // 导出的表格名称，根据需要自己命名
          // });
        });
      } else {
        _message.error(res.message);
      }
    }; //格式转换


    const formatJsonDown = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => {
        return v[j];
      }));
    };

    return {
      tableDataDay,
      tableDataMonth,
      columnsDay1,
      columnsDay2,
      columnsDay3,
      columnsDay0,
      columnsDay,
      columnsMonth,
      formState,
      yearsOptions,
      onSubmit,
      activeKey,
      dateDay,
      dateMonth,
      dateYears,
      defaultSelectDate,
      paginationConfig,
      onPageChange,
      loading,
      changeMonth,
      changeDay,
      changeYear,
      changeTabs,
      openAddReason,
      addVisible,
      handleCancelAdd,
      formStateAdd,
      btnLoading,
      onSubmitAdd,
      changeData,
      changeVisible,
      handleCancelChange,
      formStateChange,
      onSubmitChange,
      checkin,
      // selectClockInRecordAll1,
      tableColumnsDay,
      changeVisibleDay,
      handleCancelChangeDay,
      formStateChangeDay,
      handleClickMakeUpCard,
      formRef,
      tableColumnsPeople,
      daySate,
      departmentData,
      formStateForSearch,
      formRefForSearch,
      onSubmitForSearch,
      resetForSearch,
      downloadVisible,
      handleDownload,
      formStateForDownload,
      handleOkDownload,
      disabledDate
    };
  }

});